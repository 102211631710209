import React from 'react'
import { Box, Flex, FlexProps, Text } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { isRtlSelector } from 'redux/selectors/general'

interface SliderSwitchProps extends Omit<FlexProps, 'onChange' | 'isChecked'> {
  isChecked?: boolean
  onChange?: (isChecked: boolean) => void
  activeLabel: string
  inactiveLabel: string
  isEdit?: boolean
}

const SliderSwitch: React.FC<SliderSwitchProps> = ({
  isChecked,
  onChange,
  activeLabel,
  inactiveLabel,
  isEdit = false,
  ...props
}) => {
  const theme = useSelector((state: RootState) => state?.general?.theme)
  const isRtl = useSelector(isRtlSelector)

  const InactiveText = (
    <Text
      zIndex={1}
      w="50%"
      textAlign="center"
      fontSize="sm"
      fontWeight={isChecked ? 'normal' : 'bold'}
      color={isChecked ? 'gray.500' : 'white'}
      cursor="pointer"
      onClick={() => onChange?.(false)}
    >
      {inactiveLabel}
    </Text>
  )

  const ActiveText = (
    <Text
      zIndex={1}
      w="50%"
      textAlign="center"
      fontSize="sm"
      fontWeight={isChecked ? 'bold' : 'normal'}
      color={isChecked ? 'white' : 'gray.500'}
      cursor="pointer"
      onClick={() => onChange?.(true)}
    >
      {activeLabel}
    </Text>
  )

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      w="300px"
      h="45px"
      bg="white"
      borderRadius="20px"
      p="2px"
      position="relative"
      cursor="pointer"
      onClick={() => onChange?.(!isChecked)}
      pointerEvents={isEdit ? 'none' : 'auto'}
      opacity={isEdit ? 0.5 : 1}
      {...props}
    >
      <Box
        position="absolute"
        top="0px"
        left={isChecked ? 'calc(50% + 2px)' : '2px'}
        w="50%"
        h="45px"
        bg={theme?.elementsColor}
        borderRadius="20px"
        transition="left 0.3s ease"
      />

      {isRtl ? (
        <>
          {ActiveText}
          {InactiveText}
        </>
      ) : (
        <>
          {InactiveText}
          {ActiveText}
        </>
      )}
    </Flex>
  )
}

export default SliderSwitch
